import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Carousel from 'react-bootstrap/Carousel'
import "bootstrap-icons/font/bootstrap-icons.css";

const DomofonsPage = () => (
  <Layout activeItem="Домофонное обрудование">
    <Seo title="Домофонное обрудование"/>
    <h1>Домофоны</h1>
    <h2>Документы</h2>
    <i className="bi bi-file-earmark-pdf text-danger"/>&nbsp;
    <a href="../documents/Publicnaya_oferta_umnii_domofon.pdf">
      Публичная оферта Умный Домофон
    </a>
    <p/>
    <h2>Примеры выполненных работ</h2>
    <Carousel pause="false" interval="3000">
      <Carousel.Item>
        <img src="/images/domofondoor/domofon1.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/domofondoor/domofon2.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/domofondoor/domofon3.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/domofondoor/domofon4.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
    </Carousel>
  </Layout>
)

export default DomofonsPage
